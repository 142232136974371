@import 'styles/media';

.myluggage-button {
  .ant-btn-primary {
    font-size: 16px;
    font-weight: 900;
    color: #ffffff;
    padding: 15px 0;
    width: 288px;
    height: 62px;
    border-radius: 7px;

    @include mobile {
      width: 212px;
    }
  }

  .ant-btn-default {
    font-size: 16px;
    font-weight: 900;
    color: #000;
    padding: 15px 0;
    width: 288px;
    height: 62px;
    border-radius: 7px;
    border: 1px solid #000;

    @include mobile {
      width: 212px;
    }
  }
}
