@import 'styles/media';

.my-profile {
  &__navigation {
    display: grid;
    // This is for four boxes
    // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    // This solution only for two boxes
    grid-template-columns: repeat(auto-fit, minmax(200px, 290px));
    justify-content: center;
    gap: 15px;

    @include mobile {
      grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
    }
  }
}
