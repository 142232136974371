@import 'styles/media';

.preferences-comparison {
  padding: 60px 0px 20px 0px;
  background-color: #fef7f1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @include mobile {
    padding: 60px 20px 20px 20px;
  }

  &__title {
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    color: #000000;
    margin-bottom: 60px;

    @include mobile {
      font-size: 30px;
      line-height: 1.23;
    }
  }

  &__preferences {
    display: grid;
    justify-items: stretch;
    gap: 60px;

    @include mobile {
      width: 100%;
    }
  }
}
