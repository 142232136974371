@import 'styles/media';

.travel-preferences {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include small {
    margin-bottom: 70px;
  }

  @include mobile {
    margin-bottom: 70px;
  }
}
