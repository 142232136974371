@import 'styles/media';

.traveler-preference {
  margin: 20px auto 100px auto;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;

  @include mobile {
    margin: 0;
    width: 100vw;
    padding: 0 10px;
  }

  &__title {
    font-size: 45px;
    font-weight: 900;
    text-align: center;
    line-height: 1.17;
    color: #000000;
    margin-bottom: 50px;

    @include mobile {
      margin-bottom: 40px;
      font-size: 30px;
    }
  }

  &__text {
    max-width: 650px;
    font-size: 20px;
    line-height: 1.5;
    color: #000;

    @include mobile {
      padding: 0 35px;
      width: auto;
    }
  }

  &__toggle-button {
    margin-top: 50px;
    padding: 8px 20px;
    border-radius: 8px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    transition: border 150ms linear;
    cursor: pointer;

    &:hover {
      border: 1px solid #f9785c;
    }

    &-hidden {
      transform: scale(0);
      margin: 0;
    }

    @include mobile {
      margin-bottom: 50px;
    }
  }

  &__expand-icon {
    height: 18px;
    margin-right: 15px;
  }

  &__expand-text {
    font-size: 20px;
    color: #000;
  }
}
