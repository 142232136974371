@import 'styles/media';

.start-test-button .ant-btn-primary {
  font-size: 16px;
  font-weight: 900;
  color: #ffffff;
  padding: 15px 0;
  width: 288px;
  height: auto;
  border-radius: 7px;

  @include mobile {
    width: 212px;    
  }
}
