@import 'styles/media';

.my-profile {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;

    @include mobile {
      margin-bottom: 20px;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__greeting {
    font-size: 40px;
    font-weight: 900;
    line-height: 1.25;
    color: #000000;
    margin: 0;

    @include mobile {
      font-size: 35px;
      margin-bottom: 55px;
    }
  }

  &__settings {
    padding: 0 20px;
    height: 50px;
    border-radius: 7px;
    border: solid 1px #ff785d;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 150ms linear;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    @include mobile {
      align-self: flex-end;
      opacity: 1;
    }
  }

  &__settings-text {
    font-size: 18px;
    font-weight: normal;
    text-align: center;
    color: #ff785d;
    margin-right: 20px;
  }
}
