.destination-modal {
  .ant-modal {
    max-width: 1570px;
  }

  .ant-modal-content {
    border-radius: 14px;
  }

  .ant-modal-body {
    padding: 90px;
  }

  .ant-modal-close {
    transform: scale(1.5, 1.4);
    transform-origin: top right;
  }

  &__content {
    display: grid;
    grid-template-columns: minmax(500px, 750px) minmax(300px, 750px);
    gap: 80px;
  }

  &__pictures {
    display: grid;
    grid-template-columns: repeat(3, minmax(150px, 1fr));
    grid-template-rows: 500px 155px;
    gap: 25px;
  }

  &__picture-wrapper {
    cursor: pointer;
    border: 2px solid transparent;
    transition: all 250ms linear;
    border-radius: 7px;
    overflow: hidden;

    &[data-hero='true'] {
      border-color: #f9785c;
    }

    &:hover {
      filter: brightness(0.9);
    }

    &:first-child {
      pointer-events: none;
      grid-column: 1/4;
      border: none;
    }
  }

  &__picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .destination-info {
    &__rates {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 50px;
    }

    &__rate {
      border-radius: 7px;
      border: solid 1px rgba(93, 93, 93, 0.15);
      padding: 15px 20px;
      min-width: 160px;
      transition: all 150ms linear;
      cursor: pointer;

      &:first-child {
        margin-right: 30px;
      }

      &[data-clicked='true'] {
        pointer-events: none;
        border: solid 1px #f9785c;
      }

      &:hover:not([data-clicked='true']) {
        box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.09);
      }
    }

    .rate-dislike img {
      transform: rotate(180deg) scaleX(-1) translateY(-5px);
    }

    &__rate-img {
      margin-right: 18px;
    }

    &__rate-text {
      font-size: 16px;
      font-weight: normal;
      color: #000000;
      display: inline-block;
      transform: translateY(5px);
    }

    &__name {
      font-size: 30px;
      font-weight: bold;
      line-height: normal;
      color: #000000;
      margin-bottom: 30px;
      max-width: 500px;
    }

    &__description {
      font-size: 18px;
      font-weight: normal;
      line-height: normal;
      color: #000000;
      margin-bottom: 115px;
      max-width: 500px;
    }

    .ant-btn {
      width: 259px;
      height: 57px;
      border-radius: 7px;
      background-color: #f9785c;
      font-size: 18px;
      font-weight: 900;
      color: #ffffff;
    }
  }
}
