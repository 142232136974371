@import 'styles/media';

.traveler-result {
  &__header {
    background-color: #fff6ef;
    padding: 8% 0;
    width: 100%;

    @include mobile {
      padding: 80px 15px 25px 15px;
    }
  }

  &__header-container {
    margin: 0 auto;
    padding: 0 25px;
    max-width: 1200px;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 30px;

    @include mobile {
      padding: 0;
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
  }

  &__image-container {
    width: 100%;
    height: 100%;

    @include mobile {
      width: calc(100vw - 80px);
    }
  }

  &__traveler-image {
    height: auto;
    max-width: 100%;
    min-width: 200px;

    @include mobile {
      margin-top: 25px;
    }
  }
}

.traveler-info {
  width: 100%;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @include mobile {
    height: auto;
    padding-top: 0;
  }

  &__title {
    font-size: 40px;
    font-weight: 900;
    color: #000000;
    margin-bottom: 40px;

    @include mobile {
      font-size: 30px;
      margin-bottom: 27px;
    }
  }

  &__label {
    font-size: 20px;
    font-weight: 900;
    font-style: normal;
    color: #000000;
    margin-bottom: 25px;

    @include mobile {
      margin-bottom: 15px;
    }
  }

  &__specialization,
  &__skills {
    max-width: 660px;

    @include mobile {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.skills__list {
  @include mobile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: stretch;
    justify-content: space-between;
    gap: 7px;
    width: 100%;
  }
}

.specialization__list {
  margin-bottom: 50px;

  @include mobile {
    margin: 0 auto 35px auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: stretch;
    justify-content: space-between;
    gap: 7px;
    width: 100%;
  }
}
