@import 'styles/media';

.download-app {
  width: 340px;

  @include mobile {
    width: 100%;
  }

  &__plan-trip {
    & > .ant-btn {
      font-size: 16px;
      font-weight: 900;
      color: #ffffff;
      width: 100%;
      height: 60px;
      border-radius: 7px;
      background-color: #f9785c;
    }
  }

  &__or {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    color: rgba(0, 0, 0, 0.5);
    margin: 12px 0;
  }

  &__badges {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__badge {
    border-radius: 8px;
    cursor: pointer;

    @include mobile {
      width: 45%;
      background-color: #000;
    }
  }
}
