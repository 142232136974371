@import 'styles/media';

.results-comparison {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include small {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}
