@import 'styles/media';

.travel-personality {
  max-width: 1000px;
  margin: 80px auto 0 auto;
  padding: 0 20px 100px 20px;

  @include mobile {
    padding: 0 10px 50px 10px;
    width: 100vw;
    margin: 15px auto 0 auto;
  }

  &__title {
    font-size: 45px;
    font-weight: 900;
    text-align: center;
    line-height: 1.17;
    color: #000000;
    margin-bottom: 50px;

    @include mobile {
      margin-bottom: 40px;
      font-size: 30px;
    }
  }

  &__content {
    display: grid;
    justify-items: stretch;
    justify-content: stretch;
    grid-template-columns: 1fr 1fr;
    gap: 115px;

    @include mobile {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      gap: 50px;
      padding: 0 25px;
    }
  }

  .company {
    &__title {
      font-size: 30px;
      font-weight: 900;
      line-height: 1.17;
      color: #000000;

      @include mobile {
        font-size: 20px;
      }
    }

    &__text {
      font-size: 23px;
      font-weight: 500;
      line-height: 1.5;
      color: #000000;

      @include mobile {
        font-size: 18px;
      }
    }
  }

  .good-leader {
    &__title {
      font-size: 23px;
      font-weight: 900;
      color: #000000;

      @include mobile {
        font-size: 20px;
      }
    }

    &__text {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.5;
      color: #656565;
    }
  }

  .be-careful {
    margin-top: 50px;

    &__title {
      font-size: 23px;
      font-weight: 900;
      color: #000000;

      @include mobile {
        font-size: 20px;
      }
    }

    &__list-item {
      margin-top: 25px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      &:first-child {
        margin-top: 0;
      }
    }

    &__bullet {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: #f08974;
      margin-right: 15px;
      transform: translateY(10px);
    }

    &__text {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.5;
      color: #656565;
      width: calc(100% - 25px);
    }
  }
}
