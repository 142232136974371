.strengths-item {
  border-radius: 9px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  padding: 25px;
  align-self: stretch;
  min-width: calc(100vw - 40px);

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
  }

  &__icon {
    margin-right: 20px;
  }

  &__label {
    font-size: 18px;
    font-weight: 900;
    line-height: 1.39;
    color: #000000;
    margin: 0;
  }

  &__text {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.44;
    color: rgba(0, 0, 0, 0.5);
  }
}
