@import 'styles/media';

.profile-settings {
  &__back-button {
    height: 50px;
    padding: 0 25px;
    border-radius: 7px;
    border: solid 1px #f9785c;
    opacity: 0.7;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 150ms ease-in-out;
    box-sizing: border-box;
    margin-bottom: 15px;

    &:hover {
      opacity: 1;
    }

    @include mobile {
      margin-bottom: 45px;
    }
  }

  &__back-button-icon {
    transform: rotate(180deg) scale(0.9);
    margin-right: 20px;
  }

  &__back-button-text {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #f08974;
  }
}
