.text-input {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 20px;

  &__field {
    width: 100%;
    height: 50px;
    padding: 0 15px;
    display: flex;
    border-radius: 7px;
    justify-content: center;
    background-color: #fff;
    border: none;
    font-size: 16px;
    color: #000000;
    outline: none;

    &::placeholder {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.2);
    }
  }

  &__label {
    font-size: 14px;
    font-weight: 800;
    color: #555555;
    margin-bottom: 10px;
  }

  &__error {
    position: absolute;
    font-size: 11px;
    font-weight: normal;
    color: red;
    bottom: -15px;
    left: 15px;
  }
}
