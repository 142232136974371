.strengths-swiper {
  width: 100vw;
  padding: 0 20px;
  margin-top: 100px;
  transform: translateY(-100px);

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  &__title {
    font-size: 23px;
    font-weight: 900;
    line-height: 1.09;
    color: #000000;
    margin-bottom: 10px;
  }

  &__subtitle {
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    color: #000000;
    margin: 0;
  }

  &__items {
    transform: translateX(-20px);
  }

  .swiper-pagination-bullets {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
    position: absolute;
    left: calc(100vw / 2);
    transform: translateX(-70%);
  }
}

.swiper-container {
  transform: translateX(20px);
  position: relative;
}

.swiper-wrapper {
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.swiper-pagination-bullet {
  height: 11px;
  width: 11px;
  background-color: #e1e1e1;
  border-radius: 50%;
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }

  &-active {
    background-color: #f9785c;
  }
}
