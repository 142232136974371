@import 'styles/media';
@import 'styles/animation';

.profile-settings {
  height: 100vh;
  min-height: 100%;
  overflow-y: auto;
  background-color: #fef7f1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 100px 25px;
  @include loaded;

  @include mobile {
    padding: 80px 20px 100px 20px;
    height: 100%;
    overflow: none;
  }

  &__container {
    width: 1120px;
    max-width: 1120px;

    @include mobile {
      width: 100%;
    }
  }
}
