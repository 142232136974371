@import 'styles/media';

.traveler-type-main {
  padding: 84px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  background-color: #fff;

  @include mobile {
    padding: 84px 0 50px 0;
    position: relative;
    box-shadow: 0 -10px 20px 0 rgba(0, 0, 0, 0.04);
    border-radius: 25px 25px 0 0;
  }
}

.traveler-type-test--loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
