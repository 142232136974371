@import 'styles/media';

.traveler-feature {
  display: inline-block;
  margin-right: 6px;
  margin-bottom: 6px;

  @include mobile {
    margin: 0;
  }

  &__container {
    padding: 5px 10px;
    border-radius: 9px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
      height: 48px;
      justify-content: flex-start;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    margin: 0;
    margin-left: 12px;
    line-height: 1.17;
  }

  &__icon {
    height: 36px;
    width: 36px;
  }
}
