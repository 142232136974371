@import 'styles/media';

.travel-planning-container {
  width: 100vw;
  background-color: #fff6ef;
  border-radius: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.travel-planning {
  max-width: 925px;
  padding: 125px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include medium {
    padding-right: 15px;
    padding-left: 15px;
  }

  @include mobile {
    width: 100vw;
    padding: 50px 35px;
  }

  &__title {
    font-size: 30px;
    font-weight: 900;
    text-align: center;
    color: #000000;

    @include mobile {
      font-size: 25px;
    }
  }

  &__subtitle-white {
    margin-top: 50px;
    padding: 35px 125px;
    background-color: #fff;
    border-radius: 7px;

    @include mobile {
      margin-top: 25px;
      padding: 25px 30px;
    }
  }

  &__label-bold {
    font-size: 23px;
    font-weight: 900;
    color: #000000;
    margin-bottom: 25px;

    @include mobile {
      font-size: 20px;
      margin-bottom: 15px;
    }
  }

  &__text {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    color: #000000;

    @include mobile {
      font-size: 16px;
    }
  }

  &__subtitle {
    padding: 0 125px;
    margin-top: 30px;

    @include mobile {
      padding: 25px 0;
    }
  }

  &__label {
    font-size: 23px;
    font-weight: 500;
    color: #000000;

    @include mobile {
      font-size: 20px;
    }
  }
}
