@import 'styles/media';
@import 'styles/animation';

.start-trip {
  position: relative;
  min-height: 100vh;
  padding: 190px 25px 0 25px;
  @include loaded;

  @include mobile {
    padding: 30px 17px 0 17px;
    background-color: #fefaf7;
  }

  &__grid {
    margin: auto;
    max-width: 980px;
    display: grid;
    grid-template-columns: 350px minmax(350px, 1fr);
    gap: 50px;

    @include small {
      display: block;
    }
  }

  &__img-container {
    display: flex;
    justify-content: center;
    align-items: center;

    @include small {
      display: none;
    }
  }

  &__img {
    height: 490px;
    transform: translateY(20px);
  }

  &__packing {
    width: 100%;

    @include small {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__mobile-top-bar {
    display: none;

    @include mobile {
      padding: 0 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
    }
  }

  &__hashtag {
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
    color: #f9785c;
  }

  &__title {
    font-size: 40px;
    font-weight: 900;
    line-height: 1.2;
    color: #000000;
    margin-bottom: 35px;

    @include small {
      max-width: 390px;
      text-align: center;
    }

    @include mobile {
      padding: 0 50px;
      font-size: 35px;
      margin-bottom: 30px;
    }
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100vw;
    z-index: -999;

    @include mobile {
      display: none;
    }

    &__spots {
      position: relative;
      margin: 120px auto 0 auto;
      max-width: 1375px;
    }

    &__spot {
      position: absolute;
      top: 0;
      right: 25px;
      opacity: 0.9;
    }

    &__spot[data-behind] {
      top: 20px;
      right: 155px;
      opacity: 0.5;
    }

    &__bottom-area {
      width: 100%;
      position: absolute;
      bottom: 180px;
      left: 0;
    }

    &__trees {
      height: 540px;
    }

    &__hero {
      position: absolute;
      height: 200px;
      right: 300px;
      bottom: 0;
    }
  }
}
