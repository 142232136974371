@import 'styles/media';

.swiper-section {
  width: 100%;
  padding: 60px 0 120px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 21px;
  background-color: #fff6ef;
  overflow-x: hidden;

  @include mobile {
    position: relative;
    padding: 50px 0 80px 0;
    border-radius: 0;
  }

  &__title {
    font-size: 30px;
    font-weight: 900;
    color: #000000;
    margin-bottom: 50px;

    @include mobile {
      font-size: 25px;
      margin-bottom: 25px;
    }
  }

  &__list {
    display: grid;
    max-width: 1100px;
    grid-template-columns: 1fr 1fr;
    justify-items: stretch;
    justify-content: space-between;
    gap: 36px;

    @include medium {
      grid-template-columns: 1fr;
      padding: 0 15px;
    }

    @include mobile {
      display: none;
    }
  }

  &__list-mobile {
    display: none;

    @include mobile {
      display: block;
      transform: translateX(-10px);
    }
  }

  &__item {
    padding: 40px 50px;
    background-color: #fff;
    border-radius: 7px;
    align-self: stretch;

    @include medium {
      min-width: 500px;
      align-items: unset;
    }

    @include mobile {
      padding: 25px 35px;
      min-width: calc(100vw - 20px);
      border-radius: 7px;
    }
  }

  .swiper-item {
    &__header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 25px;

      @include mobile {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }

    &__icon {
      height: 35px;
      width: 35px;
      margin-right: 15px;
    }
  }

  &__label {
    font-size: 23px;
    font-weight: 900;
    color: #000000;
    margin: 0;

    @include mobile {
      font-size: 20px;
    }
  }

  &__text {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    color: #656565;

    @include mobile {
      font-size: 16px;
    }
  }

  .swiper-pagination-bullets {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 28px;
    position: absolute;
    left: calc(100vw / 2);
    transform: translateX(-50%);
  }
}

.swiper-container {
  transform: translateX(20px);
  position: relative;
}

.swiper-wrapper {
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.swiper-pagination-bullet {
  height: 11px;
  width: 11px;
  background-color: #e1e1e1;
  border-radius: 50%;
  margin-right: 15px;

  &-active {
    background-color: #f9785c;
  }
}
