@import 'styles/media';
@import 'styles/animation';

.traveler-type-test {
  position: relative;
  background-color: #fefaf7;
  @include loaded;
}

.traveler-type-test__content {
  position: relative;
}

.traveler-type-container {
  width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @include mobile {
    width: 100vw;
    max-width: 340px;
    padding: 0 10px;
  }
}

.traveler-type-header {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fefaf7;
  color: #000;

  @include mobile {
    height: auto;
    padding: 50px 0 75px 0;
    transform: translateY(25px);
  }

  &__description {
    width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      width: 100%;
    }
  }

  &__title {
    font-size: 40px;
    font-weight: 900;
    line-height: 1.17;
    text-align: center;

    @include mobile {
      font-size: 30px;
    }
  }

  &__icon {
    @include mobile {
      transform: scale(0.8);
    }
  }

  &__benefit {
    max-width: 165px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
      max-width: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__label {
    font-size: 16px;
    word-wrap: break-word;
    margin-left: 20px;
    text-align: start;

    @include mobile {
      font-size: 14px;
      margin-left: 15px;
    }
  }
}

.traveler-type-progress {
  width: 100vw;
  padding: 30px 20px;
  box-shadow: 0 -13px 14px 0 rgba(0, 0, 0, 0.04);
  background-color: #fff;

  @include mobile {
    box-shadow: none;
  }

  &__wrapper {
    max-width: 960px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  &__progressbar {
    margin-right: 50px;
    width: 100%;

    @include mobile {
      display: none;
    }

    &--mobile {
      display: none;
      position: sticky;
      bottom: 0;
      padding: 4px 30px;
      background-color: #efefef;

      @include mobile {
        display: block;
      }
    }
  }
}

.traveler-type-progress__next {
  &--disabled {
    background-color: #e1e1e1 !important;
    border: none !important;
    color: #fff !important;
    cursor: not-allowed !important;
  }
}
