@import 'styles/media';

.share-result {
  max-width: 1170px;
  padding: 0 25px;
  margin: 0 auto 100px auto;

  @include mobile {
    margin: 0 auto 60px auto;
  }

  .react-share__ShareButton {
    &:focus {
      border: none;
      outline: none;
    }

    @include mobile {
      display: none;
    }
  }

  &__title {
    font-size: 45px;
    font-weight: 900;
    line-height: 1.11;
    text-align: center;
    color: #000000;
    margin-bottom: 60px;

    @include mobile {
      font-size: 30px;
      max-width: 290px;
      margin: 0 auto 60px auto;
    }
  }

  &__link {
    width: 100%;
    height: 104px;
    border-radius: 52px;
    background-color: #fff6ef;
    padding: 12px;
    display: grid;
    grid-template-columns: minmax(200px, 1000px) 80px;
    gap: 12px;

    @include mobile {
      height: 76px;
      padding: 8px;
      margin-bottom: 50px;
      grid-template-columns: 100%;
    }
  }

  &__url-container {
    overflow: hidden;
    position: relative;
    background-color: #fff;
    border-radius: 40px;
    padding: 0 35px;
  }

  &__url {
    white-space: nowrap;
    font-size: 14px;
    font-weight: normal;
    color: #000000;
    position: absolute;
    overflow: hidden;
    width: calc(100% - 70px);
    left: 35px;
    top: 50%;
    transform: translateY(-50%);

    @include mobile {
      font-size: 20px;
    }
  }

  &__social {
    height: 80px;
    transition: opacity 150ms linear;

    &:hover {
      opacity: 0.9;
    }

    @include mobile {
      display: none;
    }
  }

  &__social-mobile {
    height: 80px;
    display: none;
    margin: 0 auto;
    text-align: center;

    @include mobile {
      display: block;
    }
  }

  &__copy {
    position: absolute;
    right: 0;
    background-color: #fff;
    height: 100%;
    width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 100;

    &::before {
      content: '';
      position: absolute;
      pointer-events: none;
      z-index: -1;
      right: 75px;
      top: 0;
      width: 150px;
      height: 100%;
      background: linear-gradient(to right, transparent, #fff);

      // iOS Safari
      @supports (-webkit-touch-callout: none) {
        display: none;
      }
    }
  }
}
