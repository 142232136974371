@import 'styles/media';

.preference-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;

  @include mobile {
    width: 100%;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  &__title {
    font-size: 18px;
    font-weight: 900;
    line-height: normal;
    text-align: center;
    color: #000000;

    @include mobile {
      font-size: 20px;
    }
  }

  &__description {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 150px 40px 1fr 40px 150px;
    column-gap: 10px;

    @include mobile {
      grid-template-columns: 38px 1fr 38px;
    }

    @include medium {
      grid-template-columns: 38px 1fr 38px;
    }
  }

  &__labels-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 5px;
    display: none;

    @include medium {
      display: flex;
    }

    @include mobile {
      display: flex;
      width: calc(100vw - 70px);
    }
  }

  &__label {
    font-size: 18px;
    font-weight: normal;
    line-height: normal;
    color: #8c8c8c;
    transform: translateY(-2px);

    @include mobile {
      display: none;
    }

    @include medium {
      display: none;
    }
  }

  &__label-mobile {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    color: #8c8c8c;
  }

  [data-position='left'] {
    text-align: right;
  }

  [data-position='right'] {
    text-align: left;
  }

  &__percent {
    font-size: 18px;
    font-weight: 900;
    line-height: normal;
    color: #f9785c;

    @include mobile {
      font-size: 16px;
    }
  }

  &__progress-bar {
    width: 20vw;
    min-width: 200px;

    &--reverse {
      transform: rotate(180deg);
    }

    @include medium {
      width: 30vw;
      min-width: 300px;
    }

    @include mobile {
      min-width: unset;
      width: calc(100vw - 160px);
    }
  }
}
