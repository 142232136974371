@import 'styles/media';

.compare-preferences {
  max-width: 1120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @include mobile {
    width: 100vw;
    padding: 0 10px;
  }

  &__overviews {
    padding: 0 10px;

    @include mobile {
      width: 100%;
    }
  }

  &__comparison-steps {
    margin-top: 40px;
    margin-bottom: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 27px 66px;
    max-width: 1120px;
    border-radius: 15px;
    box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.07);
    background-color: #ffffff;

    @include medium {
      display: none;
    }

    @include mobile {
      display: none;
    }
  }

  &__comparison-steps-mobile {
    display: none;
    width: 100%;
    margin-top: 60px;
    padding: 0 25px;
    margin-bottom: 30px;
    flex-direction: column;
    align-items: flex-start;

    @include medium {
      display: flex;
      width: auto;
    }

    @include mobile {
      width: 100%;
      display: flex;
    }
  }
}

.comparison-step {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @include medium {
    margin-bottom: 50px;
  }

  @include mobile {
    margin-bottom: 50px;
  }

  &__number {
    font-size: 40px;
    font-weight: 900;
    line-height: 1.25;
    color: #f9785c;
    margin-right: 30px;
    margin-bottom: 0;
  }

  &__label {
    font-size: 23px;
    font-weight: 900;
    line-height: 1.22;
    color: #000000;
    margin-bottom: 0;

    @include medium {
      margin-right: 0 !important;
    }

    @include mobile {
      margin-right: 0 !important;
    }
  }

  &__arrow {
    margin-right: 30px;
  }
}
