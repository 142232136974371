@import 'styles/media';

.traveler-specs {
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mobile {
    padding: 60px 35px;
  }

  &__title {
    font-size: 30px;
    font-weight: 900;
    color: #000000;
    margin-bottom: 50px;

    @include mobile {
      font-size: 25px;
      margin-bottom: 25px;
    }
  }

  &__list {
    max-width: 1100px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: stretch;
    justify-content: space-between;
    gap: 65px;

    @include medium {
      grid-template-columns: 1fr 1fr;
    }

    @include small {
      grid-template-columns: 1fr;
    }

    @include mobile {
      width: 100%;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      justify-items: stretch;
      align-items: center;
      gap: 60px;
    }
  }

  &__label {
    font-size: 23px;
    font-weight: 900;
    color: #000000;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;

    @include mobile {
      font-size: 20px;
    }
  }

  &__spec-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 25px;

    @include mobile {
      margin-bottom: 20px;
    }
  }

  &__icon {
    margin-right: 24px;
  }

  &__text {
    width: 320px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    opacity: 0.6;
    color: #000000;

    @include mobile {
      width: auto;
      font-size: 18px;
    }
  }
}
