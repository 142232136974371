@import 'styles/media';

.traveler-profile {
  padding: 100px 0;
  max-width: 985px;
  margin: 0 auto;

  @include mobile {
    width: 100%;
    padding: 60px 0;
  }

  &__header {
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    color: #000000;

    @include mobile {
      font-size: 30px;
    }
  }

  &__preferences {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
      margin-top: 50px;
      padding: 0 25px;
    }
  }
}
