@import 'styles/media';

.results-comparison {
  .friends-list {
    width: 680px;
    max-width: 680px;
    margin-right: 50px;

    @include small {
      width: 100%;
      margin-right: 0;
      margin-bottom: 50px;
    }

    @include mobile {
      width: calc(100vw - 20px);
    }

    &__title {
      padding-left: 80px;
      font-size: 30px;
      font-weight: 900;
      color: #000000;
      margin-bottom: 50px;

      @include mobile {
        padding: 0;
        font-size: 35px;
        line-break: 1.43;
        text-align: center;
      }
    }

    &__grid {
      width: 100%;
    }

    &__grid-head {
      padding-right: 10px;
      padding-left: 80px;
      display: grid;
      grid-template-columns: minmax(40px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr);
      gap: 25px;
      margin-bottom: 25px;

      @include mobile {
        display: none;
      }
    }

    &__grid-title {
      font-size: 20px;
      font-weight: bold;
      line-height: normal;
      color: #000000;

      &:last-child {
        text-align: right;
      }
    }

    &__list {
      display: grid;
      grid-template-rows: repeat(auto-fit, 50px);
      gap: 15px;

      @include mobile {
        gap: 10px;
      }
    }

    .friend {
      background-color: white;
      border-radius: 50px;
      padding: 5px 10px;
      display: grid;
      grid-template-columns: 45px minmax(40px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr);
      gap: 25px;

      @include mobile {
        gap: 10px;
        grid-template-columns: 36px minmax(50px, 1fr) minmax(90px, 1fr) minmax(80px, 1fr);
      }

      & > * {
        display: flex;
        align-items: center;
      }

      &__sign {
        justify-content: flex-start;

        & > span {
          height: 36px;
          width: 36px;
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &__name,
      &__traveler-type {
        font-size: 20px;
        font-weight: normal;
        line-height: normal;
        color: #000000;

        @include mobile {
          font-size: 16px;
        }
      }

      &__add-button-wrapper {
        justify-content: flex-end;
      }

      &__add-button {
        height: 36px;
        width: 110px;
        border-radius: 36px;
        background-color: #f9785c;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 17px;
        font-weight: bold;
        line-height: normal;
        color: #ffffff;
        cursor: pointer;
        transition: all 150ms linear;

        @include mobile {
          font-size: 16px;
        }

        &:hover {
          opacity: 0.9;
        }

        &--disabled {
          opacity: 0.2;
          cursor: not-allowed;

          &:hover {
            opacity: 0.2;
          }
        }
      }
    }
  }
}
