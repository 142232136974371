@keyframes loaded {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@mixin loaded {
  animation: loaded;
  animation-duration: 150ms;
  animation-timing-function: ease-in-out;
}
