@import 'styles/media';

.compare-preferences__header {
  max-width: 1000px;
  padding: 0 20px;
  margin-top: 80px;

  @include mobile {
    width: 100%;
    padding: 0 10px;
    margin-top: 40px;
  }
}

.compare-preferences__title {
  font-size: 45px;
  font-weight: 900;
  line-height: 1.11;
  text-align: center;
  color: #000000;
  margin-top: 20px;

  @include mobile {
    font-size: 30px;
    margin: 0;
  }
}
