@import 'styles/media';

.traveler-result {
  .CTA {
    margin: 0 auto 60px auto;
    max-width: 1000px;
    padding: 0 25px;

    &__title {
      font-size: 45px;
      font-weight: 900;
      text-align: center;
      color: #000000;
      margin-bottom: 60px;
      line-height: 1.17;

      @include mobile {
        max-width: 290px;
        font-size: 30px;
        margin-bottom: 50px;
      }
    }

    &__button {
      text-align: center;

      .ant-btn {
        height: 62px;
        min-width: 192px;
        border-radius: 7px;
        font-size: 16px;
        font-weight: 900;
        text-align: center;
        color: #ffffff;
      }
    }
  }
}
