@import 'styles/media';

.comparison-invite {
  width: 100%;
  padding: 25px 80px 50px 80px;
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 130px;

  @include mobile {
    padding: 40px 15px;
    margin-bottom: 80px;
  }

  &__title {
    font-size: 23px;
    font-weight: bold;
    line-height: normal;
    color: #000000;
    margin-bottom: 35px;
    text-align: center;

    @include mobile {
      text-align: center;
      margin-bottom: 25px;
    }
  }

  &__link {
    height: 64px;
    padding-left: 25px;
    border-radius: 7px;
    border: solid 1px #e6e6e6;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    position: relative;
    margin-top: 50px;
  }

  &__url {
    font-size: 16px;
    font-weight: normal;
    letter-spacing: normal;
    color: #000000;
    white-space: nowrap;
    position: absolute;
    left: 25px;
    max-width: calc(100% - 50px);
    overflow: hidden;
  }

  &__copy {
    position: relative;
    background-color: #fff;
    height: 100%;
    width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 100;

    &::before {
      content: '';
      position: absolute;
      pointer-events: none;
      z-index: -1;
      right: 75px;
      top: 0;
      width: 150px;
      height: 100%;
      background: linear-gradient(to right, transparent, #fff);

      // iOS Safari
      @supports (-webkit-touch-callout: none) {
        display: none;
      }
    }
  }
}
