@import 'styles/media';

.banner {
  margin-bottom: 80px;

  @include mobile {
    margin-bottom: 40px;
  }

  &__desktop {
    max-width: 100%;

    @include mobile {
      display: none;
    }
  }

  &__mobile {
    display: none;
    width: 100%;

    @include mobile {
      display: inline;
    }
  }
}
