@import 'styles/media';

.share-tasks {
  $grid-columns: 200px 150px 150px 150px 150px;

  padding: 110px 20px 150px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mobile {
    padding: 60px 0 100px 0;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;

    @include mobile {
      margin-bottom: 50px;
    }
  }

  &__title {
    font-size: 40px;
    font-weight: 900;
    line-height: 1.23;
    text-align: center;
    color: #000000;
    margin-bottom: 20px;
    max-width: calc(100vw - 40px);

    @include mobile {
      font-size: 30px;
      margin-bottom: 30px;
      max-width: 280px;
    }
  }

  &__subtitle {
    font-size: 18px;
    font-weight: normal;
    line-height: 1.28;
    text-align: center;
    color: #000000;
    max-width: 410px;
    margin: 0;

    @include mobile {
      max-width: 300px;
    }
  }

  &__start-test {
    margin-top: 100px;

    @include mobile {
      margin-top: 60px;
    }
  }
}
