@import 'styles/media';

.profile-settings-form {
  width: 100%;
  padding: 0 20%;
  margin: 0 auto;

  @include mobile {
    padding: 0;
  }

  &__header {
    margin-bottom: 30px;
  }

  &__title {
    font-size: 40px;
    font-weight: 900;
    color: #000000;

    @include mobile {
      text-align: center;
    }
  }

  .form-data {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &__fields {
      width: 100%;
      max-width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      justify-content: space-between;
      gap: 80px;
      margin-bottom: 80px;

      @include mobile {
        gap: 60px;
        margin-bottom: 60px;
      }

      & > * {
        max-width: 340px;
      }
    }

    #email {
      color: #8c8c8c;
    }

    &__actions {
      width: 320px;
      max-width: 320px;
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      gap: 50px;

      @include mobile {
        width: 100%;
        gap: 20px;
      }

      .ant-btn {
        width: 100%;
        height: 50px;
        border-radius: 7px;
        font-size: 16px;
        font-weight: 900;
        text-align: center;
      }
    }

    &__delete-account .ant-btn {
      font-weight: normal;
      border: solid 1px #f00;
      color: black;
      transition: all 150ms ease-in-out;

      &:hover {
        background-color: #f00;
        color: #fff;
        font-weight: 900;
      }
    }
  }
}
