@import 'styles/media';

$primary-color: #febf8f;
$primary-gray: #b7b7b7;
$secondary-color: #89cf9d;

@mixin graduation-level-checked($background-color) {
  background-color: $background-color;

  & .checkmark {
    opacity: 1;
    transform: scale(1);

    @include mobile {
      transform: scale(0.65);
    }
  }
}

@mixin graduation-level($level, $size, $mobile-size, $color) {
  .graduation__level[data-level='#{$level}'] {
    height: $size;
    width: $size;
    border-color: $color;

    @include mobile {
      height: $mobile-size;
      width: $mobile-size;
    }

    &:hover {
      background-color: $color;
    }
  }

  .graduation__level--checked[data-level='#{$level}'] {
    @include graduation-level-checked($color);
  }
}

.traveler-type-question {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mobile {
    padding: 0 20px;
  }
}

.question__id {
  color: #f9785c;
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 20px;
}

.question__title {
  width: 450px;
  text-align: center;
  color: #000;
  font-size: 21px;
  font-weight: 900;
  margin-bottom: 20px;

  @include mobile {
    width: 100%;
    min-width: calc(100vw - 40px);
  }
}

.question__graduation {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    width: 100%;
    max-width: 320px;
    flex-direction: column;
  }
}

.graduation__title {
  font-size: 16px;
  font-weight: 900;
  color: #000;

  @include mobile {
    display: none;
  }
}

.title-lowest {
  margin-right: 35px;
}

.title-highest {
  margin-left: 35px;
}

.graduation__container {
  width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    width: 100%;
  }
}

.graduation__level {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border-width: 3px;
  border-style: solid;
  cursor: pointer;
  transition: all 150ms linear;
}

.checkmark {
  opacity: 0;
  transform: scale(0);
  transition: all 150ms linear;
}

.question__link {
  border-radius: 50%;
}

@include graduation-level(1, 68px, 43px, $primary-color);

@include graduation-level(2, 56px, 35px, $primary-color);

@include graduation-level(3, 50px, 32px, $primary-color);

@include graduation-level(4, 45px, 28px, $primary-gray);

@include graduation-level(5, 50px, 32px, $secondary-color);

@include graduation-level(6, 56px, 35px, $secondary-color);

@include graduation-level(7, 68px, 43px, $secondary-color);

.graduation__mobile {
  width: 100%;
  display: none;

  .graduation__title--mobile {
    font-size: 16px;
    font-weight: 900;
    color: #000000;
  }

  @include mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
}
