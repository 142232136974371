@import 'styles/media';
@import 'styles/animation';

.traveler-result {
  position: relative;
  @include loaded;

  &__content {
    position: relative;
    padding-bottom: 80px;
  }

  &__compare-button {
    display: flex;
    justify-content: center;
  }
}
