@import 'styles/media';

.comparison-profiles {
  margin: 0 auto;
  padding: 80px 20px;
  max-width: 500px;

  @include mobile {
    padding: 100px 20px 80px 20px;
  }

  &__title {
    font-size: 30px;
    font-weight: 900;
    text-align: left;
    padding-left: 78px;
    color: #000000;
    margin-bottom: 50px;
    line-height: normal;
    max-width: 100%;
    word-wrap: break-word;

    @include mobile {
      padding: 0;
      text-align: center;
      margin-bottom: 40px;
    }
  }

  &__header {
    padding-right: 10px;
    padding-left: 78px;
    display: grid;
    grid-template-columns: 120px minmax(100px, 1fr);
    gap: 25px;
    margin-bottom: 25px;

    @include mobile {
      display: none;
    }
  }

  &__label {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
  }

  &__list {
    display: grid;
    grid-template-rows: repeat(auto-fit, 52px);
    gap: 13px;
  }

  .profile {
    height: 52px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.09);
    border-radius: 52px;
    padding: 0 8px;
    display: grid;
    grid-template-columns: 45px 120px minmax(100px, 1fr);
    gap: 25px;

    @include mobile {
      gap: 10px;
      grid-template-columns: 36px 120px minmax(90px, 1fr);
    }

    & > * {
      display: flex;
      align-items: center;
    }

    &__sign {
      justify-content: flex-start;

      & > span {
        height: 36px;
        width: 36px;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__name,
    &__traveler-type {
      font-size: 20px;
      font-weight: normal;
      line-height: normal;
      color: #000000;

      @include mobile {
        font-size: 16px;
      }
    }
  }
}
