@import 'styles/media';

.get-app {
  margin: 0 auto;
  background-color: #fff;
  padding: 100px 20px 200px 20px;
  max-width: 1400px;
  display: grid;
  gap: 100px;
  grid-template-columns: 1fr 600px;

  @include medium {
    grid-template-columns: 1fr;
  }

  @include mobile {
    padding: 60px 15px;
    gap: 50px;
  }

  &__view {
    text-align: center;
  }

  &__app-view {
    max-width: 660px;
    width: 100%;

    @include mobile {
      display: none;
    }
  }

  &__app-view-mobile {
    display: none;
    width: 100%;

    @include mobile {
      display: inline;
    }
  }

  &__resources {
    padding-top: 60px;
    min-width: 600px;

    @include medium {
      min-width: unset;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 0;
    }

    @include mobile {
      width: 100%;
    }
  }

  &__title {
    font-size: 40px;
    font-weight: 900;
    line-height: 1.25;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 40px;
    max-width: 600px;

    @include medium {
      text-align: center;
    }

    @include mobile {
      margin-bottom: 35px;
      font-size: 30px;
    }
  }

  &__benefits {
    list-style: none;
    display: grid;
    gap: 25px;
    margin-bottom: 40px;
  }

  &__benefit {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 23px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;

    &:before {
      content: '';
      display: inline-block;
      height: 8px;
      width: 8px;
      background-color: #000;
      border-radius: 50%;
      margin-right: 25px;

      @include mobile {
        height: 6px;
        width: 6px;
        margin-right: 15px;
      }
    }
  }
}
