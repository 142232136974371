@mixin mobile {
  @media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
    @content;
  }
}

@mixin medium {
  @media (max-width: 1120px) {
    @content;
  }
}

@mixin small {
  @media (max-width: 800px) {
    @content;
  }
}
