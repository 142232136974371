@import 'styles/animation';

.traveler-type-landing {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include loaded;
}
