@import 'styles/media';

@mixin change-progress-color($color) {
  & .ant-progress-circle .ant-progress-inner {
    background-color: $color;
  }
}

.tasks-overview {
  $grid-columns: 200px repeat(auto-fit, minmax(100px, 1fr));
  $grid-mobile-columns: 120px repeat(auto-fit, minmax(50px, 1fr));

  width: 800px;

  @include small {
    width: 100vw;
  }

  &__users {
    height: 60px;
    display: grid;
    grid-template-columns: $grid-columns;

    @include mobile {
      width: 100%;
      grid-template-columns: $grid-mobile-columns;
    }
  }

  &__username {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: normal;
    line-height: normal;
    color: #000000;

    @include mobile {
      font-size: 14px;
    }
  }

  &__unit {
    height: 120px;
    display: grid;
    grid-template-columns: $grid-columns;

    &:nth-child(even) {
      background-color: #fefaf7;
    }

    @include mobile {
      height: 90px;
      width: 100%;
      grid-template-columns: $grid-mobile-columns;
    }
  }

  &__label {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    font-size: 20px;
    font-weight: 900;
    line-height: normal;
    color: #000000;

    @include mobile {
      font-size: 16px;
      text-align: left;
      justify-content: flex-start;
      padding-left: 20px;
    }
  }

  &__progress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    & .ant-progress-circle .ant-progress-inner {
      background-color: #fff7e3;
    }

    &--danger {
      @include change-progress-color(#ffefe6);
    }

    &--perfect {
      @include change-progress-color(#e2fee3);
    }
  }

  &__icon {
    height: 13px;
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);

    @include mobile {
      top: 5px;
      transform: translateX(-50%) scale(0.7);
    }
  }

  &__progress-bar {
    & .ant-progress-circle .ant-progress-inner .ant-progress-text {
      font-size: 22px;
      font-weight: bold;
      line-height: normal;
      text-align: center;
      color: #000000;
    }

    @include mobile {
      transform: scale(0.65);
    }
  }
}
