@import 'styles/media';

.user-steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  border-radius: 15px;
  background-color: #ffffff;

  @include small {
    display: none;
  }

  @include mobile {
    display: none;
  }

  &__mobile {
    display: none;
    width: 100%;
    margin-top: 60px;
    padding: 0 25px;
    margin-bottom: 30px;
    flex-direction: column;
    align-items: center;

    @include small {
      display: flex;
      width: auto;
    }

    @include mobile {
      width: 100%;
      display: flex;
    }
  }
}

.user-step {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:last-child {
    &__label {
      margin-right: 0;
    }
  }

  @include medium {
    margin-bottom: 50px;
  }

  @include mobile {
    margin-bottom: 50px;
  }

  &__number {
    font-size: 40px;
    font-weight: 900;
    line-height: 1.25;
    color: #f9785c;
    margin-right: 25px;
    margin-bottom: 0;
  }

  &__label {
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
    color: #000000;
    margin-bottom: 0;
    max-width: 200px;
    min-width: 100px;
    margin-right: 25px;

    @include medium {
      margin-right: 10px;
    }

    @include mobile {
      margin-right: 0;
    }
  }

  &__arrow {
    margin-right: 25px;
  }
}
