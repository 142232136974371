@import 'styles/media';

.tasks-comparison {
  padding: 80px 0;

  @include mobile {
    padding: 80px 0 50px 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 120px;

    @include mobile {
      margin-bottom: 80px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    color: #000000;
    margin-bottom: 57px;

    @include mobile {
      font-size: 30px;
      margin-bottom: 37px;
      max-width: calc(100vw - 40px);
    }
  }
}
