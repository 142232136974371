@import 'styles/media';

.start-trip__search-destination {
  margin-bottom: 15px;
  border-radius: 7px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.11);
  background-color: #ffffff;
  overflow: hidden;
  height: 62px;

  .ant-select {
    width: 100%;
  }

  .ant-select-selector {
    height: 62px !important;
    padding: 0 30px !important;
    outline: none;
    border: none !important;
    box-shadow: none !important;

    @include mobile {
      padding: 0 20px !important;
    }

    & > span {
      display: flex;
      align-items: center;
      left: 30px !important;
      right: 30px !important;
      font-size: 16px !important;
      font-weight: bold !important;
      color: #000000 !important;
      opacity: 1 !important;

      @include mobile {
        left: 20px !important;
        right: 20px !important;
      }

      & > input {
        font-weight: bold !important;
        color: #000000 !important;
        transform: translateY(-4px);
      }
    }
  }
}
