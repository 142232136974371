@import 'styles/media';

.traveler-result .destinations {
  width: 100vw;

  &__wrapper {
    max-width: 1900px;
    padding: 60px 20px 40px 20px;
    margin: 0 auto;
  }

  &__title {
    font-size: 45px;
    font-weight: 900;
    line-height: 1.11;
    text-align: center;
    color: #000000;

    @include mobile {
      font-size: 35px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, minmax(250px, 1fr));
    grid-template-rows: repeat(2, 400px);
    gap: 10px;

    @include medium {
      grid-template-columns: repeat(2, minmax(250px, 1fr));
      grid-template-rows: repeat(3, 400px);
    }

    @include small {
      grid-template-columns: repeat(1, minmax(250px, 1fr));
      grid-template-rows: repeat(6, 400px);
    }

    @include mobile {
      grid-template-rows: repeat(6, 300px);
    }
  }

  .destination {
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 7px;
    transition: all 250ms linear;
    border: solid 3px transparent;
    z-index: 100;

    &:hover {
      border: solid 3px #f08974;

      & > .destination__name,
      & > .destination__hidden {
        transform: translateY(-200px);

        @include mobile {
          transform: translateY(-160px);
        }
      }

      & > .destination__img {
        filter: brightness(0.8);
      }
    }

    &__img {
      object-fit: cover;
      transition: all 250ms linear;
      width: 100%;
      height: 100%;
      filter: brightness(0.9);
    }

    &__hidden {
      width: 100%;
      position: absolute;
      transition: all 250ms ease-in-out;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 20px;
    }

    &__name {
      position: absolute;
      bottom: 0;
      min-width: 100px;
      font-size: 40px;
      font-weight: 900;
      line-height: 1.17;
      text-align: center;
      color: #ffffff;
      margin-bottom: 20px;
      width: 100%;
      transition: all 250ms ease-in-out;
      padding: 0 20px;

      @include mobile {
        font-size: 30px;
        margin-bottom: 15px;
      }
    }

    &__description {
      font-size: 18px;
      font-weight: 900;
      line-height: 1.5;
      text-align: center;
      color: #ffffff;
      margin-bottom: 15px;
      width: 100%;

      @include mobile {
        font-size: 16px;
        margin-bottom: 10px;
        line-height: 1.2;
      }
    }

    &__learn-more {
      .ant-btn {
        max-width: 260px;
        height: 57px;
        border-radius: 7px;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.06);
        font-size: 18px;
        font-weight: 900;

        @include mobile {
          height: 50px;
        }
      }
    }
  }
}
