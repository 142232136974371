@import 'styles/media';

.comparison-header {
  padding: 0 80px;
  margin-bottom: 80px;

  @include mobile {
    padding: 0;
    margin-bottom: 50px;
  }

  &__title {
    font-size: 40px;
    font-weight: 900;
    line-height: 1.25;
    color: #000000;
    margin-bottom: 10px;
    text-align: center;

    @include mobile {
      font-size: 35px;
      line-height: 1.43;
      color: #000000;
      margin-bottom: 35px;
    }
  }

  &__subtitle {
    max-width: 500px;
    font-size: 18px;
    font-weight: normal;
    line-height: normal;
    color: #000000;
    margin: 0 auto;
    text-align: center;
  }
}
