@import 'styles/media';

.register-CTA {
  max-width: 830px;
  margin: 0 auto;

  &__header {
    margin-bottom: 60px;

    @include mobile {
      margin: 0 auto 30px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__title {
    font-size: 30px;
    font-weight: 900;
    text-align: center;
    color: #000000;
    line-height: 1.17;
    margin-bottom: 30px;
    max-width: 95%;

    @include mobile {
      max-width: calc(100vw - 50px);
    }
  }

  &__subtitle {
    font-size: 23px;
    font-weight: 900;
    text-align: center;
    color: #000000;
    line-height: 1.17;
    margin-bottom: 0;

    @include mobile {
      max-width: calc(100vw - 50px);
    }
  }

  &__benefits {
    margin: 0 auto 50px auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include mobile {
      flex-direction: column;
      align-items: center;
    }
  }

  &__benefit {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mobile {
      margin-bottom: 40px;

      &:last-child {
        margin: 0;
      }
    }
  }

  &__benefit-icon {
    margin-bottom: 15px;
  }

  &__label {
    max-width: 200px;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    color: #656565;
  }

  &__sign-up {
    margin: 0 auto;
    display: grid;
    justify-content: center;
    gap: 13px;
    margin-bottom: 13px;
  }

  &__social {
    position: absolute;
    left: 22px;
    height: 24px;
  }

  &__button {
    height: 40px;
    width: 320px;
    border-radius: 7px;
    font-size: 15.5px;
    font-weight: 600;
    transition: all 150ms linear;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    cursor: pointer;

    @include mobile {
      width: calc(100vw - 50px);
    }

    &:hover {
      opacity: 0.8;
    }

    &--google {
      color: #000000;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

      &:hover {
        color: #000;
      }
    }

    &--facebook {
      background-color: #3b5998;
      color: #ffffff;

      &:hover {
        color: #fff;
      }
    }

    &--classic {
      background-color: #f9785c;
      color: #fff;

      &:hover {
        color: #fff;
      }
    }
  }

  &__sign-in {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__account-exists {
    margin-right: 0.3em;
    color: #636b6f;
  }

  &__login {
    color: #000;
    transition: color 150ms linear;

    &:hover {
      color: #f7775b;
    }
  }
}
