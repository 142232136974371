@import 'styles/media';

.compare-overview {
  margin-bottom: 60px;

  &__title {
    font-size: 20px;
    font-weight: 900;
    line-height: normal;
    text-align: center;
    color: #000000;
    grid-column-start: 2;
    margin-bottom: 22px;

    @include mobile {
      font-size: 20px;
      margin-bottom: 15px;
    }
  }

  .comparison {
    padding: 25px 50px 12px 50px;
    border-radius: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #fff;

    @include small {
      width: calc(100vw - 40px);
    }

    @include mobile {
      padding: 25px 25px 5px 25px;
      margin-bottom: 15px;
      width: 100%;
    }

    &__header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding-left: 100px;
      margin-bottom: 20px;

      @include mobile {
        width: 100%;
        margin-bottom: 15px;
        padding: 0;
      }
    }

    &__labels {
      grid-column-start: 2;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include mobile {
        width: 100%;
        grid-column-start: unset;
      }
    }

    &__label {
      font-size: 16px;
      font-weight: 900;
      line-height: normal;
      color: rgba(0, 0, 0, 0.6);
    }

    &__data {
      max-width: 100%;
      display: grid;
      grid-template-columns: 85px 30px 1fr 30px;
      margin-bottom: 13px;
      gap: 15px;

      @include mobile {
        width: 100%;
        gap: 0;
        grid-template-columns: 30px 1fr 30px;
        justify-content: space-between;
      }
    }

    &__user {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include mobile {
        display: none;
      }
    }

    &__users-mobile {
      display: none;

      @include mobile {
        margin: 0 auto;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
    }

    &__user-mobile {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 2.5px;
    }

    &__bullet {
      margin-left: 10px;
      height: 13px;
      width: 13px;
      border-radius: 50%;
    }

    &__username,
    &__percent {
      font-size: 16px;
      font-weight: normal;
      line-height: normal;
      text-align: right;
      color: #000000;

      @include mobile {
        font-weight: 500;
      }
    }

    &__progress {
      width: 477px;

      &--reverse {
        transform: rotate(180deg);
      }

      @include small {
        width: calc(100vw - 300px);
      }

      @include mobile {
        width: 100%;
        display: flex;
        justify-content: center;

        & .ant-progress-line {
          width: calc(100% - 30px);
        }
      }
    }
  }
}
