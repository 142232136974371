@import 'styles/media';

.strengths-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;

  @include mobile {
    display: none;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    @include small {
      margin-bottom: 5px;
    }
  }

  &__title {
    font-size: 23px;
    font-weight: 900;
    line-height: 1.09;
    text-align: center;
    color: #000000;
    margin-bottom: 15px;
  }

  &__subtitle {
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    color: #000000;
    margin: 0;
  }

  &__items {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex-wrap: wrap;
    max-width: 100%;
    padding: 0 20px;

    @include small {
      justify-content: center;
    }
  }
}

.strengths-list-mobile {
  display: none;

  @include mobile {
    display: block;
  }
}
