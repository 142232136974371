@import 'styles/media';

.results-comparison {
  .selected-friends {
    width: 360px;
    max-width: 360px;
    min-width: 200px;

    @include mobile {
      max-width: unset;
      width: 100%;
    }

    &__title {
      font-size: 23px;
      font-weight: bold;
      color: #000000;
      padding-left: 30px;
      margin-bottom: 25px;
    }

    &__list {
      border-radius: 9px;
      overflow: hidden;
      background-color: #fff;
    }

    &__header {
      padding: 25px 30px;
      background-color: #f9785c;

      @include mobile {
        padding: 25px;
      }
    }

    &__username {
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
    }

    .friend {
      padding: 25px 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: solid 1px rgba(151, 151, 151, 0.25);

      @include mobile {
        padding: 25px;
      }

      &__name {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: normal;
        color: #000000;
      }

      &__remove {
        padding: 7px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 150ms ease-in-out;
        transform: translateX(7px);

        &:hover {
          background-color: rgba(151, 151, 151, 0.1);
        }
      }

      &__remove-icon {
        height: 13px;
        width: 13px;
      }
    }

    &__start-comparison-wrapper {
      padding: 70px 40px 40px 40px;

      @include mobile {
        padding: 70px 25px 40px 25px;
      }

      & > .ant-btn {
        border-radius: 7px;
        width: 100%;
        height: 50px;
        font-size: 16px;
        font-weight: 900;
        text-align: center;
        color: #ffffff;

        &[disabled] {
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}
