@import 'styles/media';
@import 'styles/animation';

.my-profile {
  padding: 100px 0;
  background-color: #fef7f1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  @include loaded;

  &__container {
    width: 1250px;
    max-width: 1250px;
    padding: 0 25px;
    overflow-x: hidden;

    @include mobile {
      padding: 0 15px;
      width: 100vw;
    }
  }
}
