@import 'styles/media';

.traveler-personality {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 150px;
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
    color: #989898;

    @include mobile {
      margin-bottom: 7px;
    }
  }

  &__tooltip {
    opacity: 0;
    transform: scale(0);
    position: absolute;
    top: 30px;
    left: 0;
    width: 418px;
    padding: 25px 35px;
    transition: all 100ms linear;
    transform-origin: top;
    z-index: 10;
    font-size: 16px;
    color: #000000;
    box-shadow: 5px 5px 17px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    border-radius: 8px;

    @include mobile {
      width: 276px;
    }
  }

  &__question-mark {
    margin-left: 13px;
    transform: translateY(-18px);
    width: 22px;
    height: 22px;

    & path {
      transition: all 100ms linear;
    }

    &:hover path {
      fill: #e76f55;
    }

    &:hover + .traveler-personality__tooltip {
      opacity: 1;
      transform: scale(1);
    }

    @include mobile {
      margin-left: 7px;
    }
  }

  &__description {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 180px 40px 1fr 40px 180px;
    column-gap: 22px;

    @include mobile {
      grid-template-columns: 38px 1fr 38px;
    }
  }

  &__labels-mobile {
    display: none;
    width: 100%;

    @include mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__label {
    font-size: 20px;
    font-weight: 900;
    color: #000000;

    @include mobile {
      display: none;
    }
  }

  &__label-mobile {
    font-size: 16px;
    font-weight: 900;
    color: #000000;
  }

  [data-position='left'] {
    text-align: right;
  }

  [data-position='right'] {
    text-align: left;
  }

  &__percent {
    font-size: 20px;
    font-weight: 900;
    color: #f9785c;
  }

  &__progress-bar {
    width: 477px !important;

    @include medium {
      width: 300px !important;
    }

    @include small {
      width: 150px !important;
    }

    &--reverse {
      transform: rotate(180deg);
    }

    @include mobile {
      width: calc(100vw - 170px) !important;
    }
  }
}
