@import 'styles/media';
@import 'styles/animation';

.test-comparison {
  background-color: #fef7f1;
  min-height: 100vh;
  padding: 160px 0;
  @include loaded;

  @include mobile {
    padding: 80px 0;
  }

  &__container {
    margin: 0 auto;
    max-width: 1120px;
    padding: 0 20px;
  }
}
