.start-trip__radio-group {
  margin: 15px 0 20px 0;

  .ant-radio-group {
    max-width: 230px;
    display: flex;
    justify-content: space-between;
  }

  .ant-radio-wrapper {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    display: flex;
    align-items: center;
  }

  .ant-radio {
    transform: scale(1.2);
  }
}
