@import 'styles/media';

.checkbox {
  width: 100%;

  &__title {
    font-size: 12px;
    font-weight: 800;
    color: #555555;
  }

  &__field {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    position: relative;
  }

  &__hidden-field {
    opacity: 0;
    z-index: 100;
    position: absolute;
    border-radius: 7px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    left: 0;
    top: 0;
  }

  &__custom-box {
    width: 30px;
    height: 30px;
    border-radius: 7px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 18px;
  }

  &__checkmark {
    user-select: none;
    transition: all 150ms ease-in-out;
    transform: scale(0);

    &--checked {
      transform: scale(1);
    }
  }

  &__label {
    max-width: 220px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #555555;
    cursor: pointer;
  }
}
