@import 'styles/media';

@keyframes hide-anchor {
  from {
    opacity: 1;
    transform: translateX(0px);
  }

  to {
    opacity: 0;
    transform: translateX(-110%);
  }
}

@keyframes open-anchor {
  from {
    opacity: 0;
    transform: translateX(-110%);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.hide-anchor {
  animation: hide-anchor 200ms ease-in-out;
  animation-fill-mode: forwards;

  &:hover {
    animation-play-state: paused;
  }
}

.open-anchor {
  animation: open-anchor 200ms ease-in-out;
  animation-fill-mode: forwards;
}

.anchor-container {
  pointer-events: none;
  position: absolute;
  height: calc(100% - 110px);
  top: 0;
  left: 10px;
  transform: translateY(110px);
  z-index: 10000;

  @include small {
    display: none;
  }

  @include mobile {
    display: none;
  }
}

.anchor {
  pointer-events: all;
  position: sticky;
  left: 10px;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  padding: 25px;
  border-radius: 15px;

  &__link {
    & > a {
      color: #989898;
    }

    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 7px;

    &--active {
      & > a {
        color: #000000;
      }

      & + .anchor__sublinks {
        display: flex;
        opacity: 1;
        transform: scale(1);
      }

      &::before {
        background-color: #f9785c !important;
      }
    }

    &::before {
      content: '';
      display: block;
      height: 12px;
      width: 2px;
      background-color: #e0d9d4;
      margin-right: 22.5px;
      transform: translateX(2.5px);
    }
  }

  &__sublinks {
    display: none;
    opacity: 0;
    transform: scale(0);
    margin-left: 25px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__sublink {
    font-size: 16px;
    font-weight: bold;
    color: #989898;
    display: flex;
    align-items: center;
    margin-bottom: 7px;

    &:hover {
      color: #f9785c;
    }

    &--active {
      color: #000000;

      &::before {
        background-color: #f9785c !important;
      }
    }

    &::before {
      content: '';
      display: block;
      border-radius: 50%;
      height: 7px;
      width: 7px;
      background-color: #e0d9d4;
      margin-right: 15px;
    }
  }
}
