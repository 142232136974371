@import 'styles/media';

.daterange-picker {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;

  .ant-picker {
    font-size: 16px;
    height: 62px;
    border-radius: 7px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.11);
    background-color: #ffffff;
    border: none;
    padding: 0 25px;

    @include mobile {
      padding: 0 15px;
    }

    &-input {
      flex-direction: row-reverse;

      input {
        font-size: 16px;
        font-weight: bold;
        color: #000;

        &::placeholder {
          color: #000;
        }
      }
    }

    &-suffix {
      margin-left: 0;
      margin-right: 15px;
      color: #000;
      font-weight: normal;
      transform: translateY(-2px);
    }
  }
}
