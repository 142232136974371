@import 'styles/media';

.landing-header {
  padding: 130px 20px 100px 20px;
  background-color: #fefaf7;
  border-radius: 1px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include medium {
    padding: 80px 25px 50px 25px;
  }

  @include small {
    padding: 50px 25px;
  }

  @include mobile {
    padding: 30px 12px 70px 12px;
  }
}

.understand-preferences {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 100px;

  @include small {
    flex-direction: column-reverse;
    margin-bottom: 40px;
  }

  @include mobile {
    flex-direction: column-reverse;
    margin-bottom: 40px;
    width: 100%;
  }

  &__overview {
    margin-top: 40px;

    @include medium {
      margin: 0;
    }

    @include small {
      width: 100%;
    }

    @include mobile {
      width: 100%;
    }
  }
}

.slogan {
  border-radius: 15px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 445px;
  max-width: 445px;
  margin-left: 25px;

  @include medium {
    width: auto;
    max-width: 445px;
    min-width: 250px;
    min-height: 100%;
  }

  @include small {
    margin-bottom: 40px;
  }

  @include mobile {
    padding: 0;
    max-width: unset;
    width: 100%;
    margin-left: 0;
  }

  &__img {
    width: 100%;
    margin-bottom: 20px;

    @include mobile {
      display: none;
    }
  }

  &__mobile-img-wrapper {
    display: none;
    padding: 80px 18px 0 18px;

    @include mobile {
      display: block;
    }
  }

  &__mobile-img {
    width: 100%;
  }

  &__text {
    padding: 45px;
    text-align: center;

    @include mobile {
      padding: 45px 0;
    }
  }

  &__title {
    width: 100%;
    font-size: 30px;
    font-weight: 900;
    line-height: 1.23;
    color: #000000;
    margin-bottom: 20px;
  }

  &__subtitle {
    width: 100%;
    font-size: 18px;
    line-height: 1.28;
    color: #000000;
  }
}

.take-test {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include small {
    padding: 0 15px;
  }

  @include mobile {
    padding: 0 15px;
  }

  &__advantages {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    @include small {
      margin-bottom: 35px;
      width: 100%;
      justify-content: space-between;
    }

    @include mobile {
      margin-bottom: 35px;
      width: 100%;
      justify-content: space-between;
    }
  }

  &__advantage {
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      margin-right: 15px;
    }

    @include small {
      &:first-child {
        margin: 0;
      }
    }

    @include mobile {
      &:first-child {
        margin: 0;
      }
    }
  }

  &__icon {
    margin-right: 20px;

    @include mobile {
      transform: scale(0.8);
      margin-right: 10px;
    }
  }

  &__label {
    max-width: 150px;
    font-size: 18px;
    line-height: 1.28;
    color: #000000;

    @include mobile {
      font-size: 16px;
      line-height: 1.44;
    }
  }

  &__or {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    line-height: 1;
    margin: 12px 0;
  }
}
