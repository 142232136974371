@import 'styles/media';

.comparison-summary {
  padding: 90px 20px;
  background-color: #fef7f1;
  margin-bottom: 200px;

  @include mobile {
    margin-bottom: 100px;
  }

  &__header {
    margin-bottom: 50px;

    @include mobile {
      margin-bottom: 25px;
    }
  }

  &__title {
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    color: #000000;
    margin-bottom: 50px;

    @include mobile {
      font-size: 30px;
    }
  }

  &__subtitle {
    font-size: 25px;
    font-weight: 900;
    text-align: center;
    color: #000000;
    margin: 0;

    @include mobile {
      font-size: 20px;
    }
  }

  &__profiles-result {
    max-width: 1100px;
    padding: 40px;
    background-color: #fff;
    border-radius: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 190px));
    justify-content: center;
    gap: 15px;
    margin: 0 auto;

    @include mobile {
      padding: 20px;
      max-width: 100%;
      grid-template-columns: 1fr 1fr;
      justify-items: stretch;
      gap: 25px;
    }
  }

  &__profile {
    max-width: 190px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @include mobile {
      justify-content: space-between;
    }
  }

  &__label {
    font-size: 20px;
    font-weight: 900;
    color: #000000;

    @include mobile {
      font-size: 16px;
    }
  }

  &__username {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }

  &__sign {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    margin-right: 15px;

    @include mobile {
      font-size: 16px;
    }
  }

  &__name {
    font-size: 20px;
    font-weight: normal;
    color: #000000;

    @include mobile {
      font-size: 16px;
    }
  }

  &__tips {
    max-width: 780px;
    margin: 60px auto 0 auto;
    display: grid;
    gap: 10px;

    @include mobile {
      margin: 15px auto 0 auto;
      gap: 15px;
    }
  }

  &__tip {
    background-color: #fff;
    border-radius: 15px;
    padding: 25px 60px;

    @include mobile {
      padding: 20px;
    }
  }

  &__tip-text {
    font-size: 20px;
    line-height: normal;
    font-weight: normal;
    color: #000000;
    margin: 0;

    @include mobile {
      font-size: 16px;
    }
  }

  .destination {
    margin-top: 70px;

    @include mobile {
      margin-top: 60px;
    }

    &__label {
      font-size: 25px;
      font-weight: 900;
      text-align: center;
      color: #000000;
      margin-bottom: 40px;

      @include mobile {
        font-size: 20px;
        margin-bottom: 30px;
      }
    }

    &__location {
      margin: 0 auto;
      border-radius: 15px;
      background-color: #fff;
      max-width: 350px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 25px;
      font-weight: bold;
      color: #000000;
    }
  }
}
