@import 'styles/media';

.my-profile {
  .navigation-item {
    padding: 15px 15px 0 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-width: 150px;
    max-width: 290px;
    height: 260px;
    border-radius: 6px;
    background-color: #fff;
    cursor: pointer;
    transition: all 150ms ease-in-out;
    border: solid 2px transparent;

    @include mobile {
      min-width: 100px;
      max-width: 200px;
      max-height: 150px;
      padding: 15px 5px 0 5px;
    }

    &:hover {
      & > h2 {
        opacity: 1;
      }

      border: solid 2px #ff785d;

      & > h2 {
        opacity: 1;
      }
    }

    &__icon-wrapper {
      height: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 90%;

      @include mobile {
        height: 70px;

        & > * {
          transform: scale(0.42);
        }
      }
    }

    &__text {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
      text-align: center;
      color: #000000;
      margin: 0;
      opacity: 0.4;
      transition: all 150ms ease-in-out;

      @include mobile {
        opacity: 1;
        font-size: 18px;
      }
    }
  }
}
